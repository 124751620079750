import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  CssBaseline,
  Avatar,
  CircularProgress,
  Grid,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Swal from 'sweetalert2';
import accountService from 'services/accountService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { Navbar } from 'ui/components/navbar';
import { Footer } from 'ui/components/footer';
export const SignIn = () => {
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsLogged, setStorage } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error, data } = await accountService.login({ mail, password });
      if (error) {
        throw new Error(data);
      }

      // Login exitoso
      Swal.fire({
        icon: 'success',
        title: '¡Bienvenido!',
        text: 'Has ingresado correctamente.',
        timer: 1500,
        showConfirmButton: false,
      });

      // Redirección inmediata
      setIsLogged(true);
      setStorage('user', data);
      setStorage('data', data);
      navigate('/');
    } catch (error) {
      // Login fallido
      Swal.fire({
        icon: 'error',
        title: 'Error de acceso',
        text: error.message || 'Usuario o contraseña incorrectos.',
        timer: 1500,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Navbar/>
    <Container component="main" maxWidth="lg">
      <CssBaseline />
      <Grid container spacing={2} sx={{ mt: 8 }}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: '#0f4073' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Blocks Baratos 
            </Typography>
            <form onSubmit={handleSubmit} style={{ width: '100%', mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={mail}
                onChange={(e) => setMail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : 'Ingresar'}
              </Button>
              {/* <Link href="#" variant="body2" sx={{ display: 'block', textAlign: 'center' }}>
                ¿Olvidó su contraseña?
              </Link> */}
             
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box textAlign="center">
            <Typography variant="h6">
              ¿Deseas ser cliente de Blocks Baratos?
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Regístrate e inicia sesión y obten los mejores precios del mercado.
            </Typography>
            <Link href="/signUp" variant="body2" sx={{ display: 'block', textAlign: 'center', mt: 2 }}>
                ¿No tienes cuenta? Regístrate aquí
              </Link>
          </Box>
        </Grid>
      </Grid>

    </Container>
    <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
    <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
    <br/>  <br/>  <br/>  <br/>  <br/>  <br/>

    <Footer/>
    </>
  );
};
