import React, { useState, useEffect, Suspense } from "react";
import { Link } from "react-router-dom";
import { Compras } from "./components/compras/Compras";
import { Navbar } from "ui/components/navbar";
import { Footer } from "ui/components/footer";
import { Services } from "./components/services";
import { authHeader_get } from "_helpers";
import { isMobile } from "react-device-detect";
import { Favoritos } from "./components/favoritos/Favoritos";
import { Presentation } from "./components/presentation";
import { Banners } from "./components/banners";
import { Marcas } from "./components/marcas";
import { Chat } from "ui/components/chat";
import { Cotizador } from "ui/components/cotizador";
import { MultiServices } from "components/multiservices/MultiServices";
import { Question } from "components/question/Question";
import { servicesIsa } from "constants/servicesIsa";
import ListProduct from "components/ListProduct/ListProduct";
import ProductCarousel from "components/productCarousel/ProductCarousel";

export const HomePage = () => {
  let sectionDefault = 5;
  const category ='5'

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      {/* <Cotizador/> */}
      {/* {<Chat/>   } */}
      <Presentation />
      {/* <Services/>  */}

      {/* <Marcas/>   */}

    

    

      <div className="container" style={{ paddingTop: "25px" }}>
        <br />
        <Link to={'/venta - renta/Manipuladores telescópicos/1/Nuevo León/20'}><h1 className="section-title section-center">Brazos verticales</h1></Link>
        

        <ProductCarousel category={category} />
      </div>

      <div className="container" style={{ paddingTop: "25px" }}>
        <br />
        <h1 className="section-title section-center">Servicios</h1>
        <br />
      </div>
      <MultiServices
        list={servicesIsa}
        defaultItem={sectionDefault}
        defaultTitle="Conoce más"
      />
      <div className="container" style={{ paddingTop: "25px" }}>
        <br />
        <Link to={'/venta - renta/Elevadores de personas/2/Nuevo León/20'}><h1 className="section-title section-center">Plataformas de tijera eléctricas</h1></Link>
        

        <ProductCarousel category={'7'} />
      </div>
      <Question />

      <br />

      <Footer />
    </div>
  );
};
