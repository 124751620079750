import { useEffect, useContext } from 'react'

import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom'


import { Home } from 'views/home'
import { Login } from 'views/login'
import { About } from 'views/about'
import { Services } from 'views/services'
import { Contact } from 'components/contact'
import { DetailAmazon } from 'views/detailAmazon'
import { Products } from 'views/products'
import { Detail } from 'views/detail'
import { ProductsAmazons } from 'views/productsAmazons'
import { DetailGenie } from 'views/detailGenie'
import { SignIn } from 'views/signIn'
import { SignUp } from 'views/signUp'
const Router = (props) => {

  return (
    <Routes>
      <Route exact path='/home' index element={<Navigate to='/' replace />}  />
      <Route exact path='/' element={<Home />} key='Home' />
      <Route exact path='/about' element={<About />} key='Home' />
      <Route exact path='/services' element={<Services />} key='Home' />
      <Route exact path='/login' element={<Login />}  />
      <Route exact path='/contact/:service' element={<Contact />}  />
      {/* <Route exact path='/productos/:name/:id' element={<DetailAmazon/>} key='contact'/> */}
      <Route exact path='/:action/:name/:id/:state/:stateid' element={<ProductsAmazons/>} key='detail'/>
      <Route exact path='/detalle/:categoryname/:name/:statename/:id' element={<DetailGenie/>} key='detail'/>
      <Route exact path='/productos/:name/:id' index element={<Navigate to='/' replace />} key='LandingPage' />
      <Route exact path='/signIn' index element={<SignIn/>} key='LandingPage' />

      <Route exact path='/signUp' index element={<SignUp/>} key='LandingPage' />

    </Routes>
  )
}



export default (Router)
