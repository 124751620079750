import { getHandleResponse } from "helpers/getHandleResponse";
import { getAuthHeader } from "helpers/getAuthHeader";
const API_BASE_URL = process.env.REACT_APP_URL;

const makeRequest = async (endpoint, method, data = null) => {
  const requestOptions = {
    method,
    headers: getAuthHeader(),
  };

  if (data) {
    requestOptions.body = JSON.stringify(data);
  }

  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, requestOptions);
    const result = await getHandleResponse(response);
    return { error: false, data: result };
  } catch (error) {
    return { error: true, data: error.message };
  }
};

const accountService = {
  register: (data) => makeRequest("account/sign-up", "POST", data),
  registerClient: (data) => makeRequest("account/signup-client", "POST", data),
  registerAdmin: (data) => makeRequest("account/signup-admin", "POST", data),
  login: (data) => makeRequest("account/sign-in", "POST", data),
}

export default accountService;