export const getHandleResponse = async (response) => {
    const text = await response.text();
    const data = text ? JSON.parse(text) : {};
  
    if (!response.ok) {
      if (response.status === 401) {
        // Consider implementing auto-logout functionality here
        // logout();
      }
      throw new Error(data.message || response.statusText);
    }
  
    return data;
  };