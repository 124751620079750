import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  CssBaseline,
  Avatar,
  CircularProgress,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Swal from 'sweetalert2';
import accountService from 'services/accountService';
import { useNavigate } from 'react-router-dom';
import { Navbar } from 'ui/components/navbar';
import { Footer } from 'ui/components/footer';
import { useLocation } from 'react-router-dom';

export const SignUp = () => {
  const [mail, setMail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [motherName, setMotherName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get('type');
  const validatePassword = (password) => {
    // Verifica si la contraseña contiene al menos un carácter especial
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/; // Puedes agregar más caracteres si lo deseas
    return specialCharacterRegex.test(password);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Validación de contraseña
    if (!validatePassword(password)) {
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: 'La contraseña debe contener al menos un carácter especial (por ejemplo, $).',
        timer: 1500,
        showConfirmButton: false,
      });
      setLoading(false);
      return;
    }

    try {
      const { error, data } = await accountService.registerClient({
        mail,
        firstName,
        lastName,
        motherName,
        password,
      });
      if (error) {
        throw new Error(data);
      }

      // Registro exitoso
      Swal.fire({
        icon: 'success',
        title: '¡Registro exitoso!',
        text: 'Te has registrado correctamente.',
        timer: 1500,
        showConfirmButton: false,
      });

      // Redirección a la página de inicio de sesión
      navigate('/signin');
    } catch (error) {
      // Registro fallido
      Swal.fire({
        icon: 'error',
        title: 'Error de registro',
        text: error.message || 'Ocurrió un error al registrar.',
        timer: 1500,
        showConfirmButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Navbar/>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 8,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: '#0f4073' }}>
          <PersonAddIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registro de {(type=="business" ? 'Socio' : 'Cliente')}
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%', mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo electrónico"
            name="email"
            autoComplete="email"
            autoFocus
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="Nombre"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Apellido Paterno"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="motherName"
            label="Apellido Materno"
            name="motherName"
            value={motherName}
            onChange={(e) => setMotherName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Registrar'}
          </Button>
          <Link href="/signin" variant="body2" sx={{ display: 'block', textAlign: 'center' }}>
            ¿Ya tienes una cuenta? Inicia sesión
          </Link>
        </form>
      </Box>
    </Container>
    <br/>  <br/>  <br/>  <br/>  <br/>  <br/>
    <Footer/>
    </>
  );
};
