import { useEffect, useState } from 'react'
import { AuthContext } from './AuthContext'
import PropTypes from 'prop-types'

export const AuthProvider = ({ children = null }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [typeAuth, setTypeAuth] = useState('signin') // signin, signup, forgetPassword, successSignup

  const tmpIsLogged = window.localStorage.getItem('__user') !== null
  const [isLogged, setIsLogged] = useState(tmpIsLogged)

  const rawListFavorites = window.localStorage.getItem('__listFavorites') || '[]'
  const tmpListFavorites = JSON.parse(rawListFavorites)
  const [listFavorites, setListFavorites] = useState(tmpListFavorites)

  const [onCloseCallback, setOnCloseCallback] = useState(null)

  const [showAlert, setShowAlert] = useState(false)
  const [alertInfo, setAlertInfo] = useState({
    title: '',
    subtitle: '',
    message: ''
  })

  const getUserData = () => {
    const rawData = window.localStorage.getItem('__user') || '{}'
    const userData = JSON.parse(rawData)

    return userData
  }

  const getToken = () => {
    const rawData = window.localStorage.getItem('__data') || '{}'
    const userData = JSON.parse(rawData)

    return userData
  }

  const setStorage = (key, data) => {
    window.localStorage.setItem(`__${key}`, JSON.stringify(data))
  }

  const getStorage = (key) => {
    const rawData = window.localStorage.getItem(`__${key}`) || '{}'
    const storageData = JSON.parse(rawData)
    return storageData
  }

  const logout = () => {
    setListFavorites([])

    window.localStorage.removeItem('__user')
    window.localStorage.removeItem('__data')
    window.localStorage.clear()

    setIsLogged(false)
  }

  const getUsername = () => {
    // eslint-disable-next-line camelcase
    const { first_name } = getUserData()

    // eslint-disable-next-line camelcase
    const userName = (first_name.trim().length > 0) ? first_name : 'Door'

    return userName
  }
  const [searchKeyword, setSearchKeyword]= useState('')
  const [detailItem, setDetailItem ] = useState('')
  useEffect(() => {
    window.localStorage.setItem('__listFavorites', JSON.stringify(listFavorites))
  }, [listFavorites])

  const valuesProvider = {
    typeAuth,
    setTypeAuth,

    showDialog,
    setShowDialog,

    isLogged,
    setIsLogged,

    listFavorites,
    setListFavorites,

    showAlert,
    setShowAlert,
    onCloseCallback,
    setOnCloseCallback,

    alertInfo,
    setAlertInfo,

    getUserData,
    getToken,
    getUsername,

    logout,

    setStorage,
    getStorage,
    searchKeyword, setSearchKeyword,
    detailItem, setDetailItem
  }

  return (
    <AuthContext.Provider value={valuesProvider}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.any
}
