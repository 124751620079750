export function getAuthHeader() {
    // Obtener usuario del localStorage
    let user = JSON.parse(localStorage.getItem('__data'));

    if (user && user.token) {
        // Retornar el encabezado con el token como Bearer
        return { 
            'Authorization': `Bearer ${user.token}`, 
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Origin': '*',
          
        };
    } else {
        // Retornar solo el Content-Type si no hay token
        return { 'Content-Type': 'application/json',
            'Access-Control-Allow-Headers' : 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Origin': '*',
           
         };
    }
}
