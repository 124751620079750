import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, Box, Container,Tooltip} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { Chat } from "components/chat";
import { useAuth } from "hooks/useAuth";
import PersonIcon from '@mui/icons-material/Person';
import PersonRaisedHandIcon from '@mui/icons-material/PanTool';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
export const Navbar = () => {
  const { logout, isLogged, setIsLogged} = useAuth();
  const locations = useLocation();
  const [styleNav, setStyleNav] = useState("");
  const [categories, setCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false); // Estado para el menú móvil

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const changeColor = () => {
    if (document.documentElement.scrollTop > 50 && locations.pathname == "/") {
      setStyleNav("navbar-default");
    } else if (document.documentElement.scrollTop === 0 && locations.pathname == "/") {
      setStyleNav("navbar-home");
    }
  };

  window.addEventListener("scroll", changeColor);

  useEffect(() => {
    getCategories();
    if (locations.pathname == "/") {
      setStyleNav("navbar-home");
    } else {
      setStyleNav("navbar-default");
    }
  }, []);

  const getCategories = async () => {
    const result = await fetch_categories();
    if (result) {
      setCategories(result.categories);
    }
  };

  useEffect(() => {
    const filtered = categories.filter((item) => ![
      '19d2f330-235c-4843-949a-296cc770d9af',
      '04812c2b-038d-4551-af94-148dcccd140e',
      '12946d5d-f729-430d-b4ec-17b6285d1fcb',
      'b5d104a5-1fc2-46f2-ae59-70fcd6cf7d54',
      '20bbde65-a5d3-4fee-98cf-5f6dfae151ac',
      'e88625b3-a563-405c-aa13-88ae77d36f30'
    ].includes(item.externalId));
    setFilteredCategories(filtered);
  }, [categories]);

  const [filteredCategories, setFilteredCategories] = useState([]);

  return (
    <header>
      <AppBar position="fixed" sx={{ background: styleNav === "navbar-home" ? '#0000' : '#0f4073', boxShadow: 'none' }}>
        <Container>
          <Toolbar>
            {/* Logo BlockBaratos */}
            <a href="/"><Logo variant="h6">Blocks Baratos</Logo></a>

            {/* Links a la derecha */}
            <NavLinks>
              <NavLinkButton href="/about">Nosotros</NavLinkButton>
              <NavLinkButton href="/services">Servicios</NavLinkButton>

              {/* Menu desplegable Plataformas */}
              <div>
                <Button
                  aria-controls="platform-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  sx={{ color: '#fff', fontWeight: 500 }}
                >
                  Plataformas
                </Button>
                <Menu
                  id="platform-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  sx={{ '& .MuiPaper-root': { backgroundColor: '#333' } }}
                >
                  {filteredCategories.map((item) => (
                    <MenuItem key={item.id} onClick={handleMenuClose}>
                      <a
                        href={`/venta - renta/${item.nameSpanish}/${item.id}/todo méxico/0`}
                        style={{ color: '#fff', textDecoration: 'none' }}
                      >
                        {item.nameSpanish}
                      </a>
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </NavLinks>
            {/* SignIn Button */}
            {isLogged ? (
                            <Tooltip title="Cerrar Sesión" arrow>
                                <IconButton onClick={logout}>
                                    <LogoutIcon sx={{ color: 'white' }}/>
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Iniciar Sesión" arrow>
                                <NavLinkButton href="/signIn" passHref>
                                    <IconButton>
                                        <PersonIcon sx={{ color: 'white' }} />
                                    </IconButton>
                                </NavLinkButton>
                            </Tooltip>
                        )}

            {/* Menu Mobile */}
            <IconButton
              sx={{ display: { xs: 'block', md: 'none' }, color: '#fff' }}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <MenuIcon />
            </IconButton>

          </Toolbar>
        </Container>
      </AppBar>

      {/* Menú Móvil */}
      {mobileMenuOpen && (
        <div className="mobile-menu" style={{ position: 'fixed', top: '64px', width: '100%', background: '#333', zIndex:'9999' }}>
          <Button onClick={handleMenuClick} sx={{ color: '#fff', width: '100%' }}>Plataformas</Button>
          <div>
            {filteredCategories.map((item) => (
              <MenuItem key={item.id} onClick={handleMenuClose} sx={{ width: '100%', padding: '10px 20px' }}>
                <a
                  href={`/venta - renta/${item.nameSpanish}/${item.id}/todo méxico/0`}
                  style={{ color: '#fff', textDecoration: 'none' }}
                >
                  {item.nameSpanish}
                </a>
              </MenuItem>
            ))}
          </div>
          <Button href="/signin" sx={{ color: '#fff', width: '100%', padding: '10px 20px' }}>Sign In</Button>
        </div>
      )}

      <Chat />
    </header>
  );
};

const fetch_categories = async () =>
  (await fetch(`${process.env.REACT_APP_URL}genie/categories`)).json();

const Logo = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: '#fff',
  fontSize: '24px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
}));

const NavLinks = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    gap: '10px',
    display: 'none',
  },
}));

const NavLinkButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  fontWeight: 500,
  '&:hover': {
    color: '#FFD700', // Golden color on hover
  },
}));

