import React, { useEffect, useState } from "react";
import "./ProductCarousel.css";
import ProductComponent from "components/ProductComponent/ProductComponent";

const ProductCarousel = ({ category }) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Determinar items por slide según el ancho de la pantalla
  const getItemsPerSlide = () => {
    if (windowWidth < 576) return 1;      // xs: móviles
    if (windowWidth < 768) return 2;      // sm: tablets
    if (windowWidth < 992) return 3;      // md: tablets grandes
    return 4;                             // lg y xl: escritorio
  };

  const itemsPerSlide = getItemsPerSlide();

  useEffect(() => {
    getProducts(category);
    
    // Manejar resize de la ventana
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      // Reset del índice cuando cambia el tamaño para evitar slides vacíos
      setCurrentIndex(0);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [category]);

  const getProducts = async (id) => {
    setLoading(true);
    let result = await fetch_products(id);
    setProducts(result.products);
    setLoading(false);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + itemsPerSlide >= products.length ? 0 : prevIndex + itemsPerSlide
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - itemsPerSlide < 0
        ? Math.max(products.length - itemsPerSlide, 0)
        : prevIndex - itemsPerSlide
    );
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  const totalSlides = Math.floor(products.length / itemsPerSlide );
  const currentSlide = Math.floor(currentIndex / itemsPerSlide );
  
  return (
    <div className="position-relative carousel-container mx-3">
      {/* Navigation Buttons */}
      {products.length > itemsPerSlide && (
        <>
          <button
            className="carousel-control-prev"
            type="button"
            onClick={prevSlide}
          >
            <span className="carousel-control-prev-icon bg-dark rounded-circle" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>

          <button
            className="carousel-control-next"
            type="button"
            onClick={nextSlide}
          >
            <span className="carousel-control-next-icon bg-dark rounded-circle" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </>
      )}

      {/* Products Container */}
      <div className="carousel-inner px-4">
        <div
          className="row flex-nowrap transition-transform"
          style={{
            transform: `translateX(-${(currentIndex * 100) / itemsPerSlide -1}%)`,
            transition: "transform 0.5s ease-in-out"
          }}
        >
          {products.map((item) => (
            item.imageUrl && ( 
              <div 
                key={item.id} 
                className={`carousel-item-wrapper col-${12/itemsPerSlide}`}
              >
                 <ProductComponent category={category} item={item}/>
              </div>
            )
          ))}
        </div>
      </div>

      {/* Indicators */}
      {totalSlides > 0 && (
        <div className="carousel-indicators position-relative mt-3">
          {Array.from({ length: totalSlides }, (_, i) => (
            <button
              key={i}
              type="button"
              className={`bg-primary ${currentSlide === i ? 'active' : ''}`}
              onClick={() => setCurrentIndex(i * itemsPerSlide)}
              style={{
                width: "30px",
                height: "3px",
                border: "none",
                opacity: currentSlide === i ? 1 : 0.5
              }}
            ></button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProductCarousel;

const fetch_products = async (categoryId) =>
  (await fetch(`${process.env.REACT_APP_URL}genie/search?categoryId=${categoryId}&stateId=20&page=1`)).json();