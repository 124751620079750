import React, { useState, useEffect, Suspense } from "react";
import { useNavigate } from "react-router-dom";
export const Search = ({type}) => {
  const [categories, setCategories] = useState([]);
  const [states,setStates] = useState([])
  const [state, setState] = useState(0);
  const [idCategory, setIdCategory] = useState(0);
  const [action, setAction] = useState("venta");
  const [zone, setZone] = useState("Centro");

  const navigate = useNavigate();

  useEffect(() => {
    //sendAlert()
    // window.alert(JSON.stringify(locations.pathname))
    getCategories();
    getStates();
  }, []);

  const getCategories = async () => {
    const result = await fetch_categories();
    if (result) {
      setCategories(result.categories);
    }
  };
  const getStates = async ()=>{
    const result = await fetch_states();
    if (result) {
       setStates(result.states);
    }
  }

  const changeState = (e) => {
    setState(e.target.value);
  };
  const changeCategory = (e) => {
    setIdCategory(e.target.value);
  };
  const changeAction = (e) => {
    setAction(e.target.value);
  };
  const changeZone = (e) =>{
    setZone(e.target.value)
  }

  const nameProduct = (name) => {
    switch (name) {
      case "Telescopic Boom Lifts":
        name = "Plataformas telescópicas";
        break;
      case "Articulated Boom Lifts":
        name = "Plataformas articuladas";
        break;
      case "Telehandlers":
        name = "Manipuladores telescópicos";
        break;
      case "Aerial Work Platforms":
        name = "Elevadores de personas";
        break;
      case "Vertical Mast Lifts":
        name = "Brazos verticales";
        break;
      case "Material Lifts":
        name = "Elevadores de Material";
        break;
      case "Slab Scissor Lifts":
        name = "Plataformas de tijera eléctricas";
        break;

      case "Trailer Mounted Boom Lifts":
        name = "Plataformas articuladas remolcables";
        break;
      case "Rough Terrain Scissor Lifts":
        name = "Plataformas de tijera todo terreno";
        break;
    }

    return name;
  };

  const goSearch = () => {
    if (action) {
      let resultCategory = categories.filter((it) => (it.id == idCategory));
      let nameCategory = idCategory ==0 ? "plataformas" : resultCategory[0].nameSpanish
      let resultState = states.filter((ite)=>(ite.id == state ))
      let nameState = state ==0 ? "todo méxico" :resultState[0].name
      navigate(
        `/${action}/${nameCategory}/${idCategory}/${nameState}/${state}`
      );
    }
  };

  return (
    <div className={"buscador"+ (type=="infinite" ? 'xxx':'') }>
      <div className="row">
       
        <div className="col-md-3 separador">
          <select
            type="select"
            className="buscador-text"
            placeholder=" Buscar"
            onChange={(e) => changeCategory(e)}
          >
             <option value="0">Todo</option>
            {categories &&
              categories.map((item) =>
                item.externalId != "19d2f330-235c-4843-949a-296cc770d9af" &&
                item.externalId != "04812c2b-038d-4551-af94-148dcccd140e" &&
                item.externalId != "12946d5d-f729-430d-b4ec-17b6285d1fcb" &&
                item.externalId != "b5d104a5-1fc2-46f2-ae59-70fcd6cf7d54" &&
                item.externalId != "20bbde65-a5d3-4fee-98cf-5f6dfae151ac" &&
                item.externalId != "e88625b3-a563-405c-aa13-88ae77d36f30" ? (
                  <option value={item.id}>{(item.nameSpanish)}</option>
                ) : (
                  ""
                )
              )}
             
          </select>
        </div>
        <div className="col-md-2 separador">
          <select
            type="select"
            className="buscador-text"
            placeholder=" Marcas"
            //onChange={(e) => changeAction(e)}
          >
            <option value="venta">Genie</option>

            {/* <option value="renta">Renta</option> */}
          </select>
        </div>
        <div className="col-md-2 separador">
          <select
            type="select"
            className="buscador-text"
            placeholder=" Buscar"
            onChange={(e) => changeAction(e)}
          >
            <option value="venta">Venta</option>

            <option value="renta">Renta</option>
          </select>
        </div>
         
        <div className="col-md-2 separador">
          <select
            type="select"
            className="buscador-text"
            placeholder=" Buscar"
            onChange={(e) => changeState(e)}
          >
            <option value="0">Todo México</option>
            {states &&
              states.map((item)=>(
                <option value={item.id}>{item.name}</option>
              ))
            }
          </select>
        </div>
        <div className="col-md-2 separador">
          <select
            type="select"
            className="buscador-text"
            placeholder=" Zona"
            onChange={(e) => changeZone(e)}
          >
            <option value="centro">Centro</option>
            <option value="sendero">Sendero</option>
            <option value="sendero">Oriente</option>
            <option value="sendero">Norte</option>
            <option value="sendero">Sur</option>

          </select>
        </div>
        <div className="col-md-1 separador">
          <button className="btn btn-primarybtn" onClick={() => goSearch()}>
            Buscar
          </button>
        </div>
      </div>
    </div>
  );
};

const fetch_categories = async () =>
  (
    await fetch(
      `${process.env.REACT_APP_URL}genie/categories`
    )
  ).json();


  const fetch_states = async () =>
    (
      await fetch(
        `${process.env.REACT_APP_URL}genie/states`
      )
    ).json();
